.icon-container {
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d3d3d;
}
.text-aboutheading{
  font: 30px Verdana, Arial, sans-serif !important;
  font-weight: 700 !important;
}
.text-about{
  font: 20px Verdana, Arial, sans-serif !important;
}
.diplypagination{
  display: flex;
  width: 100%;
}
.hidden {
  display: none;
}
.icon-container:hover {
  background-color: #4e4e4e;
}

.icon-container svg {
  fill: white;
}
.hehe {
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
  background-color: #3d3d3d;
  padding: 12px;
  /* border-radius: 8px; */
}
.hehe:hover {
  background-color: #4e4e4e;
}

.hehe svg {
  fill: white;
}
.menu-icon {
  fill: white;
  pointer-events: none;
  height: 24px; /* Add any height you want here */
}
.inerContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .selectField{
    margin-left: 10px;
    background-color: #444;
    color: white;
    border-radius: 12px;
    border: none;
    height: 42px;
    min-width: 140px !important;
    max-width: 140px !important;
  }
  .miniManu{
    margin-right: 10px;
        border-radius: 12px;
        bottom: 0;
        pointer-events: auto;
        background-color: #444;
        overflow: hidden;
  }
  .miniManu .inerContainer {
    list-style-type: none;
    display: flex;
  }
  
  .miniManu .inerContainer li {
    cursor: pointer;
    padding: 9px 13px 9px 13px;
    transition: background 0.3s;
  }

  
  .display {
    display: block; /* or flex, inline-block, etc., as needed */
  }
  .miniManu .inerContainer li.active {
    background: #07d; /* Highlighted background for the selected item */
    border-radius: 9%;
    height: 42px;
  }
  .footerContainer{
    display: none;
  }
  .inerContainer{
    height: 42px;
    border-radius: 9%;
}
  
  .mobileManue{
    position: fixed;
    bottom: 15px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .selectField{
    min-width: 140px !important;
    max-width: 140px !important;  }
}


@media screen and (max-width: 450px) {
  .selectField{
    width: 75px;
    min-width: 75px !important;
    max-width: 75px !important; 
    font-size: 13px !important;
  }
  select:not([size]){
    background-position: right 0.35rem center !important;
    background-repeat: no-repeat;
    background-size: 0.75em 0.75em;
    padding-right: 1.3rem !important;
  }
}
@media screen and (min-width: 1100px) {
  .mobileManue{
    display: none;
  }
}