@tailwind base;
@tailwind components;
@tailwind utilities;

 body{
  font-family: Verdana, Arial, sans-serif;
 }
/* AreaChart.css */
.apexcharts-tooltip {
  color: #000 !important;
}
.text17{
  font-size: 1.9rem;
}
.text-lg{
  line-height: 1.75rem;
  font-size: 1.225rem;
}
tr{
  font-weight: 700;
}
th{
  font-weight: 700;
}
.filterButtons{
  min-width: max-content;
}

.apexcharts-tooltip,
.apexcharts-tooltip.active,
.apexcharts-xaxistooltip,
.apexcharts-xaxistooltip.active,
.apexcharts-marker {
   transition: none !important;
}

.apexcharts-tooltip{
  top: -10% !important;
  /* left: 2% !important; */
  padding: 0 10px;
  text-align: center;
  position: absolute;
}