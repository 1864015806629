
.popupFont h3{
    font-family:  Verdana, Arial, sans-serif;

}
@media (max-width: 1100px) {
    .popupFont h3{
        font: 14px Verdana, Arial, sans-serif;
    
    }
    .paddingMobile{
        padding: 1rem !important;
    }
  }

  @media (max-width: 600px) {
    .popupFont h3{
        font: 12px Verdana, Arial, sans-serif;
    
    }
    .popupFont button{
        font: 12px Verdana, Arial, sans-serif;
    
    }
    .paddingMobile{
        padding: 1rem !important;
    }
  }
  
  