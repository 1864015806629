.hidden-below-1100px {
    display: none; /* Hidden by default on small screens */
  }
  table{
    
    font: 20px Verdana, Arial, sans-serif;

  }
  th {
    font-weight: 400;
    font-size: 20px;
    
    font: 20px Verdana, Arial, sans-serif;
}
tr {
    font-weight: 400;
    /* display: flex; */
    justify-content: space-between;    
    font: 20px Verdana, Arial, sans-serif;
    white-space: nowrap;
}

@media (max-width: 1000px) {

  table{
    font: 14px Verdana, Arial, sans-serif;

  }
  th {
    font-size: 14px;
    font: 14px Verdana, Arial, sans-serif;
}
tr {  
    font: 14px Verdana, Arial, sans-serif;
}
  
}
  @media (min-width: 1100px) {
    .hidden-below-1100px {
      display: block; /* Shown on large screens */
    }
  

    .table-section {


      position: relative; /* To contain the absolute positioned table header */
    }
    
    /* Table Styling */
    table {
      width: 100%;
      border-collapse: collapse;
      position: relative;
    }
/*     
    thead.abc {
      position: relative; 
      transform: translateY(0); 
      transition: transform 0.1s ease; 
      z-index: 1; 
    } */
    
    /* Sticky Class Applied via JavaScript */
    /* thead.abc.sticky {
      transform: translateY(150px); 
      opacity: 1;
      position: fixed; 
      top: 60px; 
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    } */
  
  }

  @media (max-width: 1100px) {
    .scrollbar-container{
        width: 100vw;
    }
    thead.abc{
      position: sticky;
      top: 0px;
      width: 100%;
    }

  }

  
  .hidden-above-1100px {
    display: block; /* Visible by default on small screens */
  }
  
  @media (min-width: 1100px) {
    .hidden-above-1100px {
      display: none; /* Hidden on large screens */
    }
  }
  th{
    width: 8%;
    min-width: 8%;
    max-width: 8%;
  }
  td{
    width: 8%;
    min-width: 8%;
    max-width: 8%;
  }
  .scrollbar-container {
    position: relative;
    height: 100%;
    margin: 0px auto;
}

.tablesc{
  padding-bottom: 30px;
}


.stopp{
  position: fixed;
  top: 90px;
  width: 100%;

  /* text-align: center; */
  /* th{
    width: 9.7%;
    min-width: 9.7%;
    max-width: 9.7%;
    text-align: center;
  } */
}

.stopp .name{
    width: 12%;
    min-width: 12%;
    max-width: 12%;

}

.stopp2{
/*   
  td{
    width: 9.7%;
    min-width: 9.7%;
    max-width: 9.7%;
    text-align: center;
  } */
}

