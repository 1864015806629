
.insidePopup h3{
    font-family:  Verdana, Arial, sans-serif;
}

.insidePopup button{
    font-family:  Verdana, Arial, sans-serif;


}
@media (max-width: 1100px) {
    .insidePopup h3{
        font: 14px Verdana, Arial, sans-serif;
    }
    
    .insidePopup button{
        font: 14px Verdana, Arial, sans-serif;
       
    
    }
    
  }

  @media (max-width: 600px) {
    .insidePopup h3{
        font: 12px Verdana, Arial, sans-serif;
    }
    
    .insidePopup button{
        font: 12px Verdana, Arial, sans-serif;
        padding-right: 10px;
        padding-left: 10px;
    
    }
    
  }
  