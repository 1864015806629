.mobilepop{
    font: 14px Verdana, Arial, sans-serif;

}
.mobilepop p{
    font: 14px Verdana, Arial, sans-serif;

}

@media (max-width: 600px) {
    .mobilepop{
        font: 12px Verdana, Arial, sans-serif;
    
    }
    .mobilepop p{
        font: 12px Verdana, Arial, sans-serif;
    
    }
  }
  