.text17{
  font: 1.6rem Verdana, Arial, sans-serif;
}
.customNav{
  position: sticky;
  top: 0px;
  z-index: 1000000000;
  box-shadow: none !important;
}
@media screen and (max-width: 1100px) {
  .header-second-part{
    display: none;
  }

  .search-bar-container {
    display: none; 
  }
  
  .search-icon-button {
    display: inline-block;
  }
  .customNav{
    padding: 0px 12px 0px 0px !important
  }
  .webLogo{
    height: 65px !important;
  }
  .text17{
    font-size: 1.6rem !important;
    width: 260px !important;
  }
  }

  @media screen and (max-width: 600px) {
    .webLogo{
      height: 60px !important;
      width: 60px !important;

    }
    .text17{
      font-size: 1.2rem !important;
      width: 232px !important;
    }
  }

  @media screen and (max-width: 400px) {
    .webLogo{
      height: 55px !important;
    }
    .text17{
      font-size: 1.3rem !important;
    }
  }

  @media screen and (max-width: 450px) {
    .webLogo{
      height: 60px !important;

    }
    /* .logoText{
      display: none;
    } */
  }


  @media screen and (min-width: 1100px) {
    .search-bar-container.hidden {
      display: block !important;
    }
    .search-toggle-button {
        display: none;
      }
  }

  



  