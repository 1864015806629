

.left5is{
  font: 14px Verdana, Arial, sans-serif;
  padding-right: 10px;
      padding-left: 10px;
      margin-top: -5px;
      margin-bottom: 4px;
}
@media screen and (max-width: 1100px) {
    .bubblesScreen{
        display: none;

    }
    .left5is{
      left: 5px;
      padding-right: 10px;
      padding-left: 10px;
      margin-top: -5px;
    }
}
  @media screen and (min-width: 1100px) {
    .bubblesScreen{
        display: block;
    }
    .left5is{
      left: 20px;
    }
  }

